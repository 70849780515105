<template>
  <gli-dialog
    :title="isNew ? $t('Add new venue') : $t('Edit venue')"
    :isNew="isNew"
    :editedObject="editedVenue"
    @delete="deleteVenue(editedVenue.id)"
    @save="save"
    @cancel="cancel"
  >
    <v-row dense>
      <v-col cols="12">
        <gli-text-field
          v-model="name"
          :label="$t('Name')"
          required
          :rules="[rules.required]"
          :autofocus="isNew"
        ></gli-text-field>
      </v-col>
      <v-col cols="12">
        <gli-text-field
          :maxLength="200"
          v-model="description"
          :label="$t('Description')"
        ></gli-text-field>
      </v-col>
      <v-col cols="12" v-if="isSuperAdmin">
        <v-select
          item-text="name"
          item-value="id"
          :items="organizations"
          v-model="organizationId"
          :label="$t('Organization')"
          :rules="[rules.required]"
        ></v-select>
      </v-col>
      <v-col cols="12">
        <v-select
          item-text="name"
          item-value="value"
          :items="basemaps"
          v-model="defaultBasemap"
          :label="$t('Default basemap style')"
          :rules="[rules.required]"
        >
        </v-select>
      </v-col>
      <v-col cols="6">
        <gli-text-field
          v-model="webhookUrl"
          :label="$t('Webhook URL')"
          :rules="[rules.noSlashOnEnd]"
        ></gli-text-field>
      </v-col>
      <v-col cols="6">
        <gli-text-field v-model="webhookApiKey" :label="$t('Webhook API key')"></gli-text-field>
      </v-col>
      <v-col cols="12">
        <v-row class="pl-3 pt-3">{{ $t('Style settings') }}</v-row>
        <v-row class="py-0 px-2">
          <v-col cols="4" class="pa-1">
            <v-select
              item-text="name"
              item-value="_id"
              :items="organizationStyles"
              v-model="styles.standard"
              :label="$t('Standard style')"
              clearable
              solo
            ></v-select>
          </v-col>
          <v-col cols="4" class="pa-1">
            <v-select
              item-text="name"
              item-value="_id"
              :items="organizationStyles"
              v-model="styles.light"
              :label="$t('Light style')"
              clearable
              solo
            ></v-select>
          </v-col>
          <v-col cols="4" class="pa-1">
            <v-select
              item-text="name"
              item-value="_id"
              :items="organizationStyles"
              v-model="styles.dark"
              :label="$t('Dark style')"
              clearable
              solo
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="8">
        <v-data-table
          :mobile-breakpoint="0"
          :headers="headers.map((item) => ({ ...item, text: $t(item.text) }))"
          fixed-header
          :items="editedVenue.sites"
          item-key="id"
          :options.sync="pagination"
        >
          <template v-slot:item.name="{ value }">
            <span>{{ value }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <gli-editor-button
                  class="ml-2"
                  color="error"
                  fab
                  x-small
                  dark
                  v-on="on"
                  @click="removeSite(item.id)"
                >
                  <v-icon>mdi-delete</v-icon>
                </gli-editor-button>
              </template>
              <span>{{ $t('Remove') }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="4" align="center" align-self="center">
        <gli-editor-button color="primary" @click="addSiteDialog = true">{{
          $t('Add site')
        }}</gli-editor-button>
        <v-tooltip bottom :disabled="!isNew && !isDirty">
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <gli-editor-button
                class="my-3"
                color="primary"
                @click="editVenueConnections"
                :disabled="isNew || isDirty"
                >{{ $t('Edit transitions') }}</gli-editor-button
              >
            </div>
          </template>
          <span>{{ $t('Save the venue first!') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-dialog max-width="400" v-if="addSiteDialog" v-model="addSiteDialog">
      <v-card>
        <v-card-title class="primary white--text">
          {{ $t('Add site') }}
        </v-card-title>
        <v-card-text class="pa-4">
          <v-autocomplete
            class="mt-4"
            @input="siteSelected"
            v-model="selectedSite"
            :items="organizationSites"
            item-text="name"
            item-value="id"
            :label="$t('Site search')"
            hide-details
            return-object
            clearable
            dense
            autofocus
            hide-no-data
          >
          </v-autocomplete>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions
          ><v-spacer></v-spacer><v-btn @click="addSiteDialog = false">{{ $t('Cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <venue-connections-dialog v-if="editingVenueConnections"></venue-connections-dialog>
  </gli-dialog>
</template>

<script>
import { BaseStyle } from '@/types/types';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import rules from '../mixins/rules';
import VenueConnectionsDialog from './VenueConnectionsDialog.vue';
import GliTextField from './base/GliTextField.vue';

export default {
  components: { GliTextField, VenueConnectionsDialog },
  name: 'VenueDialog',

  data() {
    return {
      addSiteDialog: false,
      selectedSite: undefined,
      isDirty: false,
      headers: [
        {
          text: 'Site',
          value: 'name'
        },
        {
          text: '',
          value: 'actions',
          width: 75,
          sortable: false,
          align: 'end'
        }
      ],
      pagination: {
        page: 1,
        itemsPerPage: 5
      },
      basemaps: [
        { name: this.$t('standard'), value: BaseStyle.Standard },
        { name: this.$t('light'), value: BaseStyle.Light },
        { name: this.$t('dark'), value: BaseStyle.Dark },
        { name: this.$t('streets'), value: BaseStyle.Streets }
      ]
    };
  },
  computed: {
    ...mapFields('venue', [
      'editedVenue.name',
      'editedVenue.description',
      'editedVenue.styles',
      'editedVenue.webhookUrl',
      'editedVenue.webhookApiKey'
    ]),
    ...mapGetters('account', ['isSuperAdmin']),
    ...mapGetters('organization', ['organizations']),
    ...mapGetters('site', ['sites']),
    ...mapGetters('venue', ['isNew', 'editedVenue', 'editingVenueConnections']),

    defaultBasemap: {
      get() {
        return this.editedVenue.basemap;
      },
      set(value) {
        this.setBasemap(value);
      }
    },

    organizationId: {
      get() {
        return this.editedVenue.organizationId;
      },
      set(value) {
        this.setOrganization(value);
      }
    },

    organizationSites() {
      if (this.organizationId) {
        return this.sites.filter((site) => site.organizationId === this.organizationId);
      } else {
        return [];
      }
    },

    organizationStyles() {
      if (this.organizationId && this.organizations.length > 0) {
        const organization = this.organizations.find(
          (org) => org.id.toString() === this.organizationId.toString()
        );
        return organization?.settings?.styles ? organization.settings.styles : [];
      } else {
        return [];
      }
    }
  },

  watch: {
    'editedVenue.sites.length'() {
      this.isDirty = true;
    }
  },

  async mounted() {
    await this.getAllSites();
    await this.getAllOrganizations();
  },

  methods: {
    ...mapActions('venue', {
      save: 'save',
      cancel: 'cancel',
      deleteVenue: 'delete'
    }),
    ...mapActions('site', { getAllSites: 'getAll' }),
    ...mapActions('venue', ['editVenueConnections', 'removeSite']),
    ...mapMutations('venue', ['addSite', 'setOrganization', 'setBasemap']),
    ...mapActions('organization', { getAllOrganizations: 'getAll' }),

    siteSelected(site) {
      if (site) {
        this.addSite(site);
        this.addSiteDialog = false;
      }

      this.$nextTick(() => {
        this.selectedSite = null;
      });
    }
  },
  mixins: [rules]
};
</script>
